import {
	type LoaderFunctionArgs,
	type MetaFunction,
	type HeadersArgs,
	data,
	useLoaderData,
} from 'react-router'
import { useSearchParams } from 'react-router'
import { isbot } from 'isbot'
import { useTranslation } from 'react-i18next'
import { LinkButton } from '~/components/button/link-button'
import { LoadMoreButton } from '~/components/button/load-more-button'
import Container from '~/components/container/container'
import EventList from '~/components/event-list/event-list'
import { Icon } from '~/components/icon/icon'
import {
	IndexSection,
	IndexTitle,
} from '~/components/index-section/index-section'
import { Spacer } from '~/components/spacer/spacer'
import EventListSwiper from '~/components/swiper/event-list-swiper'
import Text from '~/components/text/text'
import { getIndexEvents } from '~/queries/indexEvents/getIndexEvents.server'
import { getPopularIndexEvents } from '~/queries/indexEvents/getPopularIndexEvents.server'
import { getPromotedIndexEvents } from '~/queries/indexEvents/getPromotedIndexEvents.server'
import { getServerLang } from '~/utils/i18n.utils'
import { getUserLocation } from '~/utils/location//location.server'
import { getUrlQueries } from '~/utils/queries/getUrlQueries.server'
import { makeTimings, time } from '~/utils/timing.server'
import { SetMeta } from '~/utils/seo/seo'
import type { EventIndex } from '~/types/eventIndex'
import i18next from '~/configs/i18next.server'

export async function loader({ params, request }: LoaderFunctionArgs) {
	const timings = makeTimings('index loader')
	const userLocation = await getUserLocation(request, params)
	const { radius, latitude, longitude, havePosition } = userLocation
	const { fromDate, toDate, categories, page, searchText } =
		await getUrlQueries(request)
	const isBot = isbot(request.headers.get('user-agent'))
	const pageSize = 20
	const lng = getServerLang(request)
	const t = await i18next.getFixedT(lng.lngShort, 'common')
	const promotedEvents = await time(
		async () =>
			await getPromotedIndexEvents({
				fromDate: fromDate,
				toDate: toDate,
				categories: categories,
				take: 9,
				isBot: isBot,
				searchText: searchText,
				lng: lng,
			}),
		{ timings, type: 'get promoted events' },
	)
	const popularEvents = await time(
		async () => {
			return await getPopularIndexEvents({
				fromDate: fromDate,
				toDate: toDate,
				categories: categories,
				take: 9,
				excludeEventIds: promotedEvents.map(e => e.dateId),
				searchText: searchText,
				lng: lng,
			})
		},
		{ timings, type: 'get popular events' },
	)
	const allEvents = await time(
		async () =>
			await getIndexEvents({
				fromDate: fromDate,
				toDate: toDate,
				categories: categories,
				take: pageSize * page,
				searchText: searchText,
				lng: lng,
			}),
		{ timings, type: 'get all events' },
	)
	let closeEvents: EventIndex[] = []
	if (havePosition) {
		closeEvents = await time(
			async () =>
				await getIndexEvents({
					fromDate: fromDate,
					toDate: toDate,
					radius: radius,
					latitude: latitude,
					longitude: longitude,
					categories: categories,
					searchText: searchText,
					lng: lng,
					take: 8,
				}),
			{ timings, type: 'get close events' },
		)
	}

	return data(
		{
			closeEvents,
			promotedEvents,
			popularEvents,
			allEvents,
			page,
			pageSize,
			title: t('public_evently_slogan'),
			description: t('public_evently_slogan_description'),
		},
		{ headers: { 'Server-Timing': timings.toString() } },
	)
}

export function headers({ parentHeaders, loaderHeaders }: HeadersArgs) {
	parentHeaders.append('Server-Timing', loaderHeaders.get('Server-Timing')!)
	return parentHeaders
}

export default function Index() {
	const {
		closeEvents,
		promotedEvents,
		popularEvents,
		allEvents,
		page,
		pageSize,
	} = useLoaderData<typeof loader>()
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const sp = new URLSearchParams(searchParams.toString())

	return (
		<Container marginY={false} className="mt-4">
			{promotedEvents.length >= 3 && (
				<IndexSection>
					<IndexTitle title={t('public_promoted')} />
					<EventListSwiper events={promotedEvents} type="promoted" />
				</IndexSection>
			)}
			{closeEvents.length > 0 && (
				<IndexSection>
					<IndexTitle
						title={t('public_events_near_you')}
						showMoreUrl={
							'/place?nearby=true' + (sp.size > 0 ? '&' + sp.toString() : '')
						}
					/>
					<EventList
						events={closeEvents}
						showFavorite
						aspectRatio="video"
						size="md"
					/>
				</IndexSection>
			)}
			{popularEvents.length >= 3 && (
				<IndexSection>
					<IndexTitle title={t('public_popular')} />
					<EventListSwiper events={popularEvents} type="popular" />
				</IndexSection>
			)}
			{allEvents.length > 0 && (
				<IndexSection>
					<IndexTitle
						title={t('public_all_events')}
						showMoreUrl={'/place' + (sp.size > 0 ? '?' + sp.toString() : '')}
					/>
					<EventList
						events={allEvents}
						showFavorite
						aspectRatio="video"
						size="md"
					/>
				</IndexSection>
			)}

			{allEvents.length <= 0 && (
				<section className="mt-12 flex h-full justify-center text-center align-middle">
					<div className="max-w-2xl">
						<Icon
							name="face-down"
							size="xxxxl"
							className="text-secondary drop-shadow-md"
						/>
						<Text as="h2" className="my-2">
							{t('error_no_events_in_search')}
						</Text>
						<Text>{t('action_try_again_or_clear_search')}</Text>
						<Spacer size="4xs" />
						<LinkButton to="/">{t('action_clear_search')}</LinkButton>
						<Spacer size="md" />
					</div>
				</section>
			)}

			<LoadMoreButton
				page={page}
				pageSize={pageSize}
				count={allEvents.length}
				text={t('action_load_more_events')}
				loadingText={t('events_loading_events')}
			/>
		</Container>
	)
}

export const meta: MetaFunction<typeof loader> = ({ location, data }) => {
	return SetMeta({
		title: data?.title,
		description: data?.description,
		location,
		root: true,
	})
}
